.ldsEllipsis {
  display: inline-block;
  height: 80px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  z-index: 100000000;
}
.ldsEllipsis div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: #ff6125;
  border-radius: 50%;
  height: 13px;
  position: absolute;
  top: 33px;
  width: 13px;
}
.ldsEllipsis div:nth-child(1) {
  animation: ldsEllipsis1 0.6s infinite;
  left: 8px;
}
.ldsEllipsis div:nth-child(2) {
  animation: ldsEllipsis2 0.6s infinite;
  left: 8px;
}
.ldsEllipsis div:nth-child(3) {
  animation: ldsEllipsis2 0.6s infinite;
  left: 32px;
}
.ldsEllipsis div:nth-child(4) {
  animation: ldsEllipsis3 0.6s infinite;
  left: 56px;
}
@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
